import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Drawer as MaterialUiDrawer, Divider, Toolbar, Hidden, Button, Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useAppInsightsContext } from '../../telemetry/AppInsightsContext';
import useComponentTracking from '../../telemetry/useComponentTracking';
import { drawerWidth, drawerMinWidth, appBarHeight } from './AppBar';
import { INavigationNode } from '../../models/INavigationNode';
import { Link } from '@reach/router';
import SvgIcon from '../../svgicons/SvgIcon';

interface INavigationDrawer {
    onDrawerToggle(): void;
    navigationTree: INavigationNode | null;
    onApplicationSelected(navigationNode: INavigationNode): void;
}

interface IResponsiveNavigationDrawer extends INavigationDrawer {
    isDrawerOpen: boolean;
    isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: '25%',
                flexShrink: 0,
            },
            [theme.breakpoints.up('lg')]: {
                width: drawerWidth,
            },
            '& a': {
                textDecoration: 'none',
            },
        },
        toolbarDrawer: {
            minHeight: '12px',
            padding: '16px',
            background: theme.colors.navBar,
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        drawerPaper: {
            width: drawerWidth,
            minWidth: drawerMinWidth,
            background: theme.colors.navBar,
            maxHeight: '100%',
            overflow: 'hidden',
            [theme.breakpoints.up('sm')]: {
                maxHeight: '60%',
                marginTop: appBarHeight,
                height: 'auto',
                paddingBottom: '25px',
            },
            borderRadius: '0 25px 25px 0',
            border: '1px solid' + theme.colors.navBarBorder,
            boxShadow: '5px 4px 7px' + theme.colors.navBarBorder,
            position: 'absolute',
        },
        drawerButton: {
            color: '#000',
            textTransform: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            display: 'block',
            border: '1px solid' + theme.colors.navBarBorder,
            borderRadius: '25px',
            margin: 0,
        },
        drawerTextDivider: {
            color: '#000',
            width: '100%',
            background: theme.colors.navBar,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            padding: '1em 2em',
            fontSize: '1.1rem',
        },
        svgIconWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1em 0 0',
        },
        drawerGrid: {
            padding: '0 20px',
            '& a': {
                textDecoration: 'none',
            },
        },
        menuSupport: {
            marginTop: '5%',
            display: 'block',
            color: '#000',
            fontWeight: 600,
            padding: '1em 2em',
            fontSize: '1.1rem',
        },
        menuSupportButton: {
            border: '1px solid' + theme.colors.navBarBorder,
            '& a': {
                textDecoration: 'none',
                color: '#000',
            },
        },
    })
);

const Drawer: React.FC<INavigationDrawer> = ({ onDrawerToggle, navigationTree, onApplicationSelected }) => {
    const classes = useStyles();
    const aiPlugin = useAppInsightsContext();

    const trackIndexTreeComponent = useComponentTracking(aiPlugin, 'navigationTree');

    const handleNavigationDrawerButtonPress = (applicationNode: INavigationNode) => {
        onApplicationSelected(applicationNode);
    };

    return (
        <>
            <Toolbar className={classes.toolbarDrawer} onMouseOver={trackIndexTreeComponent}>
                <Hidden mdUp implementation="css">
                    <Button onClick={onDrawerToggle}>
                        <CancelIcon color="secondary" fontSize="large" />
                    </Button>
                </Hidden>
            </Toolbar>
            <Divider />
            <Typography className={classes.drawerTextDivider}>By Technology</Typography>
            <Grid container justify="center" alignItems="center" spacing={1} className={classes.drawerGrid}>
                {navigationTree?.children.map((c) => (
                    <Grid key={c.applicationName} item xs={6}>
                        <Link to={`/document/${c.applicationName}`} onClick={() => handleNavigationDrawerButtonPress(c)}>
                            <Button className={classes.drawerButton}>
                                <div className={classes.svgIconWrapper}>
                                    <SvgIcon name={c.applicationName} size={60} />
                                </div>
                                <div>
                                    <Typography>{c.applicationName}</Typography>
                                </div>
                            </Button>
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Hidden mdUp implementation="css">
                <Typography className={classes.menuSupport}>Support</Typography>
                <Button className={classes.menuSupportButton}>
                    <a href="/support">Book a help session</a>
                </Button>
            </Hidden>
        </>
    );
};

const ResponsiveNavigationDrawer: React.FC<IResponsiveNavigationDrawer> = ({
    onDrawerToggle,
    isDrawerOpen,
    navigationTree,
    onApplicationSelected,
    isLoading,
}) => {
    const classes = useStyles();

    const container = window !== undefined ? () => window.document.body : undefined;

    const drawer = (
        <Drawer
            onDrawerToggle={onDrawerToggle}
            navigationTree={navigationTree}
            onApplicationSelected={onApplicationSelected}
        />
    );

    return (
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                <MaterialUiDrawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={onDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // for better open performance on mobile
                    }}
                >
                    {drawer}
                </MaterialUiDrawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <MaterialUiDrawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open={isDrawerOpen}
                >
                    {drawer}
                </MaterialUiDrawer>
            </Hidden>
        </nav>
    );
};

export default ResponsiveNavigationDrawer;
