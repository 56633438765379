import React from 'react';
import IBaseSvgProps from '../IBaseSvgProps';

const Clock: React.FC<IBaseSvgProps> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 5.644 5.644"
    >
      <defs>
        <clipPath id="clipPath1719" clipPathUnits="userSpaceOnUse">
          <path d="M1034.24 1439.477h16v16h-16z"></path>
        </clipPath>
      </defs>
      <g transform="translate(-68.993 -72.684)">
        <g transform="matrix(.35278 0 0 -.35278 -295.864 586.144)">
          <g clipPath="url(#clipPath1719)">
            <g transform="translate(1043.24 1447.877)">
              <path
                fill="#666"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l2.7-2.7-1.4-1.4L-2-.8v5.4h2zm-1-6.4a6 6 0 106 6 6.018 6.018 0 00-6-6m0 14a8 8 0 110-16 8 8 0 018 8 8.024 8.024 0 01-8 8"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Clock;