import React from "react";
import { createLightTheme } from "../theme";
import { Theme } from "@material-ui/core";

export interface IThemeContext {
  theme: Theme;
  setCurrentTheme: (currentTheme: Theme) => void;
}

export const defaultThemeState: IThemeContext = {
  theme: createLightTheme(),
  setCurrentTheme: () => {},
};

export const ThemeContext = React.createContext<IThemeContext>(
  defaultThemeState
);
