import { useState, useEffect } from "react";

const usePersistedState = <T>(
  defaultState: T,
  key: string,
  sync: boolean = true
): [T, (x: React.SetStateAction<T>) => void] => {
  const restored = localStorage.getItem(key);
  const [value, setValue] = useState<T>(
    restored ? JSON.parse(restored) : defaultState
  );

  const setValueInStorage = (update: React.SetStateAction<T>): void => {
    let newValue = update;
    if (typeof update === "function") {
      newValue = (update as (x: T) => T)(value);
    }

    localStorage.setItem(key, JSON.stringify(newValue));
    setValue(newValue);
  };

  useEffect(() => {
    if (!sync) {
      return;
    }
    const handler = (e: StorageEvent) => {
      if (e.key === key) {
        if (e.newValue) {
          setValue(JSON.parse(e.newValue));
        } else {
          setValue(defaultState);
        }
      }
    };

    window.addEventListener("storage", handler);

    return () => window.removeEventListener("storage", handler);
  }, [defaultState, key, sync]);

  return [value, setValueInStorage];
};

export default usePersistedState;
