import React from 'react';
import IBaseSvgProps from './IBaseSvgProps';
import * as icons from './icons';

interface ISvgIconProps extends IBaseSvgProps {
    name: string;
}

const svgIconComponents: { [key: string]: React.FC<IBaseSvgProps> } = { ...icons };

const SvgIcon: React.FC<ISvgIconProps> = ({ name, size }) => {
    const IconComponent = svgIconComponents[name.replace(/\s/g, '')];

    return IconComponent !== undefined ? <IconComponent size={size} /> : <icons.TrustedHelpT size={size} />;
};

export default SvgIcon;
