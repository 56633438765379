import React from 'react';
import { IThemeContext } from './ThemeContext';
import { createLightTheme } from '../theme';
import { Theme } from '@material-ui/core';

export const useThemeContext = (): IThemeContext => {
    const localStorageSetting = window.localStorage.getItem('theme');

    const isDefaultDark = localStorageSetting
        ? localStorageSetting === 'dark'
            ? true
            : false
        : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [theme, setTheme] = React.useState(createLightTheme);

    const setCurrentTheme = React.useCallback((currentTheme: Theme): void => {
        setTheme(currentTheme);
        window.localStorage.setItem('theme', currentTheme.palette.type);
    }, []);

    return {
        theme,
        setCurrentTheme,
    };
};
