import React from 'react';
import IBaseSvgProps from '../IBaseSvgProps';

const OneDrive: React.FC<IBaseSvgProps> = ({ size }) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1030.04 659.922"
      width={size}
      height={size}
    >
      <path
        d="M622.292 445.338l212.613-203.327C790.741 69.804 615.338-33.996 443.13 10.168a321.9 321.9 0 00-188.921 134.837c3.291-.083 368.083 300.333 368.083 300.333z"
        fill="#0364B8"
      />
      <path
        d="M392.776 183.283l-.01.035a256.233 256.233 0 00-135.267-38.397c-1.104 0-2.189.07-3.291.083C112.064 146.765-1.74 263.423.02 405.567a257.389 257.389 0 0046.244 144.04l318.528-39.894 244.209-196.915-216.225-129.515z"
        fill="#0078D4"
      />
      <path
        d="M834.905 242.012c-4.674-.312-9.371-.528-14.123-.528a208.464 208.464 0 00-82.93 17.117l-.006-.022-128.844 54.22 142.041 175.456 253.934 61.728c54.799-101.732 16.752-228.625-84.98-283.424a209.23 209.23 0 00-85.091-24.546v-.001z"
        fill="#1490DF"
      />
      <path
        d="M46.264 549.607C94.359 618.756 173.27 659.966 257.5 659.922h563.281c76.946.022 147.691-42.202 184.195-109.937L609.001 312.798 46.264 549.607z"
        fill="#28A8EA"
      />
    </svg>
    );
};

export default OneDrive;
