import React from 'react';
import { Link as RouterLink, RouteComponentProps, useLocation } from '@reach/router';
import { Link, Typography, makeStyles, createStyles } from '@material-ui/core';
import Layout from '../features/layout/Layout';

const useStyles = makeStyles(() =>
    createStyles({
        notFoundPage: {
            marginTop: 40,
        },
    })
);

const NotFound: React.FC<RouteComponentProps> = () => {
    const location = useLocation();
    const classes = useStyles();

    return (
        <Layout isDrawerPermitted={true}>
            <div className={classes.notFoundPage}>
                <Typography align="center">
                    Unfortunately we couldn't find a page for "<code>{location.pathname}</code>". Use{' '}
                    <Link component={RouterLink} to="/" color="secondary">
                        this link
                    </Link>{' '}
                    to go to the Home Page.
                </Typography>
            </div>
        </Layout>
    );
};

export default NotFound;
