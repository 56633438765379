import React, { createContext } from 'react';
import { reactPlugin } from './AppInsights';
import ReactGA from 'react-ga';

const AppInsightsContext = createContext(reactPlugin);

ReactGA.initialize('G-G5G56V2YML');

const AppInsightsContextProvider: React.FC = ({ children }) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};

const useAppInsightsContext = () => React.useContext(AppInsightsContext);

export { AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext };
