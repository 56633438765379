import { API_URI } from "../environmentVariables";
import { useState, useEffect, useCallback, useRef } from "react";
import React from "react";

const getValidBody = (body: {}) => {
  if (typeof body === "string") {
    return `"${body}"`;
  }
  if (body instanceof FormData) {
    return body;
  }

  return JSON.stringify(body);
};

const isResponseJson = (response: Response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

const handleResponse = (response: Response) => {
  if (isResponseJson(response)) {
    return response.json();
  }
  if (response.status === 204) {
    return Promise.resolve(null);
  }
  return response;
};

const getParameterOutput = (key: string, value: any): string => {
  if (value instanceof Array) {
    return (value as Array<string>)
      .map((v) => getParameterOutput(key, v))
      .join("&");
  }

  return `${key}=${value}`;
};

interface FetchConfig {
  doNotLogin?: boolean;
  signal?: AbortSignal;
}

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

const getPostPutRequestParams = async (
  method: RequestMethod,
  body?: {},
  headers?: {}
) => {
  const standardHeaders: Headers = new Headers();
  if (!(body instanceof FormData)) {
    standardHeaders.set("Content-Type", "application/json");
  }

  const params: RequestInit = {
    method,
  };
  if (body !== null && typeof body !== "undefined") {
    params.body = getValidBody(body);
  }

  return params;
};

const apiRequest = async (
  url: string,
  params: RequestInit,
  config?: FetchConfig
): Promise<any> => {
  const requestParams = { ...params, signal: config && config.signal };
  const address = url.startsWith("http") ? url : API_URI + url;
  const response = await fetch(address, requestParams);
  if (response.ok) {
    return handleResponse(response);
  }
  const errorText = isResponseJson(response)
    ? await response.json()
    : await response.text();
  return Promise.reject({ status: response.status, message: errorText });
};

const apiGet = async (
  url: string | null,
  parameters?: Record<string, any>,
  config?: FetchConfig
) => {
  if (url === null) {
    return new Promise<null>((resolve) => resolve(null));
  }

  if (parameters) {
    if (url.indexOf("?") <= -1) {
      url = url + "?";
    } else {
      url = url + "&";
    }

    url =
      url +
      Object.keys(parameters)
        .map((k) => getParameterOutput(k, parameters[k]))
        .join("&");
  }

  const params: RequestInit = {
    method: "GET",
  };

  return await apiRequest(url, params, config);
};

const apiGetMany = (...urls: (string | null)[]) => {
  const promises = [] as Promise<any>[];
  urls.forEach((u) => promises.push(apiGet(u)));
  return Promise.all(promises);
};

const apiPut = async (
  url: string,
  body?: {},
  headers?: {},
  config?: FetchConfig
) => {
  const requestParams = await getPostPutRequestParams("PUT", body, headers);
  return await apiRequest(url, requestParams, config);
};

const apiPatch = async (
  url: string,
  body?: {},
  headers?: {},
  config?: FetchConfig
) => {
  const requestParams = await getPostPutRequestParams("PATCH", body, headers);
  return await apiRequest(url, requestParams, config);
};

const apiPost = async (
  url: string,
  body?: {},
  headers?: {},
  config?: FetchConfig
) => {
  const requestParams = await getPostPutRequestParams("POST", body, headers);
  return await apiRequest(url, requestParams, config);
};

const apiDelete = async (url: string, config?: FetchConfig) => {
  const params: RequestInit = {
    method: "DELETE",
  };
  return await apiRequest(url, params, config);
};

const useDataUnlessForbidden = <T>(
  url: string | null,
  errorCallback?: (err: Error) => void
): [T | null, boolean, boolean, () => Promise<void>] => {
  const errorCallbackWith403Check = (err: Error & any): void => {
    if (err.status === 403) {
      setIsForbidden(true);
    }

    if (errorCallback !== undefined) {
      errorCallback(err);
    }
  };

  const refreshFunctionWith403Reset = (): Promise<void> => {
    setIsForbidden(false);
    return refreshFunction();
  };

  const [data, isDataLoading, refreshFunction] = useData<T>(
    url,
    errorCallbackWith403Check
  );
  const [isForbidden, setIsForbidden] = React.useState(false);

  return [data, isDataLoading, isForbidden, refreshFunctionWith403Reset];
};

const useData = <T>(
  url: string | null,
  errorCallback?: (err: Error & any) => void
): [T | null, boolean, () => Promise<void>] => {
  const [data, setData] = useState<T | null>(null);
  const [activeRequests, setActiveRequests] = useState(0);

  const lastActiveRequestController = useRef<AbortController | null>();

  const getData = useCallback(() => {
    const removeRequest = () => setActiveRequests((prev) => prev - 1);

    if (lastActiveRequestController.current) {
      lastActiveRequestController.current.abort();
    }
    setActiveRequests((prev) => prev + 1);

    const controller = new AbortController();
    const signal = controller.signal;

    lastActiveRequestController.current = controller;

    const promise = apiGet(url, undefined, { signal })
      .then((result) => {
        removeRequest();
        if (!signal.aborted) {
          setData(result);
        }
      })
      .catch((err) => {
        removeRequest();
        // Avoid showing an error message if the fetch was aborted
        if (err.name !== "AbortError") {
          if (errorCallback !== undefined) {
            errorCallback(err);
          } else {
            throw err;
          }
        }
      });

    return [promise, controller] as [Promise<void>, AbortController];
  }, [url]);

  useEffect(() => {
    if (url) {
      const [, controller] = getData();
      return () => {
        controller.abort();
      };
    }
  }, [url, getData]);

  const refreshFunction = () => {
    const [promise] = getData();
    return promise;
  };

  return [data, activeRequests > 0, refreshFunction];
};

export {
  apiGet,
  apiPost,
  apiPut,
  apiPatch,
  apiDelete,
  apiGetMany,
  useData,
  useDataUnlessForbidden,
};
