import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerRoot: {
            backgroundColor: theme.colors.footerPurple,
            width: '100%',
            padding: '10% 5%',
            '& a, p': {
                color: '#9795a5',
                margin: '2% auto',
                lineHeight: 1,
                fontSize: '1rem',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '35px 15%',
            },
        },
        footerTop: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            '& img': {
                maxWidth: '200px',
            },
            [theme.breakpoints.down('sm')]: {
                '& > *': {
                    flexBasis: '100%',
                    marginBottom: '5%',
                },
                '& img': {
                    maxWidth: '50%',
                },
            },
        },
        footerLinks: {
            width: '50%',
            textAlign: 'center',
            marginTop: '7%',
            '& a': {
                margin: 'auto 5%',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
                marginTop: 0,
                '& a:first-child': {
                    marginLeft: 0,
                },
            },
        },
    })
);

const Footer = ({}) => {
    const classes = useStyles();
    return (
        <footer className={classes.footerRoot}>
            <div className={classes.footerTop}>
                <img alt="waterstons-logo" src="/images/waterstons-white2020.png" />
                <div className={classes.footerLinks}>
                    <Link href="https://www.waterstons.com/miscellaneous/privacy-notice">PRIVACY POLICY</Link>
                    <Link href="https://www.waterstons.com/miscellaneous/cookie-notice">COOKIE NOTICE</Link>
                </div>
            </div>
            <Typography variant="body2">Copyright Waterstons Ltd. {new Date().getFullYear()}</Typography>
            <Typography variant="body2">Company no 3818424. VAT no 605757824</Typography>
            <Typography variant="body2">Registered office: Liddon Court, Aykley Heads, Durham, DH1 5TS</Typography>
            <Typography variant="body2">
                This site uses analytical cookies to improve the user experience and help us understand how it's
                used.
            </Typography>
        </footer>
    );
};

export default Footer;
