import React from 'react';
import { makeStyles, createStyles, Theme, Button as MuiButton, useTheme } from '@material-ui/core';

type colour = 'guides' | 'essentials' | 'recipes' | '';

interface IButtonProps {
    colour: colour;
    children: React.ReactNode;
    className?: string;
    href?: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: 'white',
            padding: '10px 25px',
            borderRadius: '25px',
            margin: '2rem 0',
            fontSize: '1.1rem',
            display: 'block',
        },
    })
);

const Button: React.FC<IButtonProps> = ({ colour, children, className, href, onClick }) => {
    const classes = useStyles({ colour });
    const theme = useTheme();

    const backgroundColour =
        colour === 'guides'
            ? theme.colors.guidesPeach
            : colour === 'essentials'
            ? theme.colors.essentialsOrange
            : colour === 'recipes'
            ? theme.colors.tabAccent
            : '';

    return (
        <MuiButton href={href} className={classes.root + ' ' + className} style={{ backgroundColor: backgroundColour }} onClick={onClick} >
            {children}
        </MuiButton>
    );
};

export default Button;
