import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette';
import './App.css';

const colors = {
    navBar: '#fff', // White
    navBarAccent: '#555556', // Medium Grey
    navBarBorder: '#e4e4e48f', // Light low opacity Grey
    menuAccent: '#252525', // Dark Grey
    background: '#fff', // White
    tabColour: '#F2F2F2', // Light Grey
    tabAccent: '#c44095', // Purple
    trustedOrange: '#FE5C4D', // Guide Orange
    trustedPurple: '#9C40DD', // Funky Purple
    guidesPeach: '#fd6b5d', // Peach
    essentialsOrange: '#f6953f', // Orange
    footerPurple: '#3f3f59', // Purple
};

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        colors: {
            navBar: string;
            navBarAccent: string;
            navBarBorder: string;
            menuAccent: string;
            background: string;
            tabAccent: string;
            tabColour: string;
            trustedOrange: string;
            trustedPurple: string;
            guidesPeach: string;
            essentialsOrange: string;
            footerPurple: string;
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        colors?: {
            navBar?: string;
            navBarAccent?: string;
            navBarBorder?: string;
            menuAccent?: string;
            background?: string;
            tabAccent?: string;
            tabColour?: string;
            trustedOrange?: string;
            trustedPurple?: string;
            guidesPeach?: string;
            essentialsOrange?: string;
            footerPurple?: string;
        };
    }
}

const typography = (palette: Palette): TypographyOptions => {
    return {
        h2: {
            fontFamily: ['"Lato"', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        },
        h3: {
            fontFamily: ['"Lato"', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        },
        h5: {
            fontFamily: '"Source Sans Pro", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            fontWeight: 300,
            fontSize: '2rem',
        },
        body1: {
            fontFamily: ['"Lato"', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        },
        body2: {
            fontFamily: ['"Source Sans Pro"', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
                ','
            ),
            fontSize: '1.4rem',
            fontWeight: 'lighter',
        },
        button: {
            margin: 'auto 10%',
            color: 'black',
            fontFamily: "'Lato', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'",
        },
    };
};

const palette = (isDarkMode: boolean): PaletteOptions => {
    return {
        type: isDarkMode ? 'dark' : 'light',
        background: {
            default: '#fff',
        },
        primary: {
            // main: "#7c4dff",
            main: '#372476',
        },
        secondary: {
            // main: "#fb8c00",
            main: '#f6953f',
        },
        error: {
            main: red.A400,
        },
    };
};

const createLightTheme = () =>
    responsiveFontSizes(
        createMuiTheme({
            typography: typography,
            palette: palette(false),
            colors: colors,
        })
    );

const createDarkTheme = () =>
    responsiveFontSizes(
        createMuiTheme({
            typography: typography,
            palette: palette(true),
            colors: colors,
        })
    );

export { createLightTheme, createDarkTheme };
