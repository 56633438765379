import React from 'react';
import { Typography, Theme, Snackbar, Button, makeStyles, createStyles } from '@material-ui/core';
import usePersistedState from '../../services/usePersistedState';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            left: 'initial',
            transform: 'none',
            bottom: 0,
            '& > div': {
                borderRadius: 0,
                display: 'flex',
                flexWrap: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap'
                },
            },
            '& .MuiSnackbarContent-action': {
                minWidth: '130px',
                [theme.breakpoints.down('sm')]: {
                    minWidth: '200px',
                    margin: 0,
                    padding: 0,
                    '& button': {
                        marginLeft: 0
                    }
                },
                '& button': {
                    textTransform: 'none',
                    backgroundColor: theme.colors.tabAccent
                }
            }
        },
        cookieText: {
            fontSize: '0.9rem',
            '& a': {
                color: theme.colors.tabAccent
            }
        }
    })
)

const TermsAndConditions: React.FC = () => {
    const [accepted, setAccepted] = usePersistedState<boolean>(false, 'tandc-consent');
    const classes = useStyles();
    const handleAccept = () => setAccepted(true);

    return (
        <Snackbar
            open={!accepted}
            classes={{root: classes.root}}
            message={
                <Typography className={classes.cookieText}>
                    We use cookies to ensure that we give you the best experience on our website. If you continue without changing your settings, we'll assume that you are happy to receive all cookies. However, you can change your cookie settings at any time. For further information about how we use cookies and how to change your settings, please read our{' '}
                    <a href='https://www.waterstons.com/miscellaneous/cookie-notice' target="_blank" rel="noopener noreferrer">
                        Cookie Notice
                    </a>
                </Typography>
            }
            action={
                <Button key="accept" color="inherit" size="medium" onClick={handleAccept}>
                    I'm fine with this
                </Button>
            }
        />
    );
};

export default TermsAndConditions;
