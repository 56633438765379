import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { AppBar as MaterialUIAppBar, Toolbar, IconButton, Hidden, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useAppInsightsContext } from '../../telemetry/AppInsightsContext';
import useComponentTracking from '../../telemetry/useComponentTracking';

//@ts-ignore
import Logo from './TrustedHelpLogo.png';
import Button from '../documentIndex/navigation/Button';

interface IAppBar {
    onDrawerToggle(): void;
    headerRef?: React.Ref<HTMLInputElement>;
    onHeaderImageLoad?(): any;
    headerIsSticky?: boolean;
}

const drawerWidth = '20%';
const drawerMinWidth = 260;
const appBarHeight = 200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        menuButton: {
            margin: theme.spacing(1),
            color: theme.colors.menuAccent,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.colors.background,
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #efefef',
            },
        },
        stickyAppBar: {
            [theme.breakpoints.up('sm')]: {
                '& > div': {
                    margin: 0,
                }
            },
        },
        toolBar: {
            justifyContent: 'space-between',
            margin: '20px',
            transition: '.2s margin',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
                margin: '10px 20px',
                '& a': {
                    width: '75%',
                },
            },
        },
        logo: {
            margin: theme.spacing(2),
            width: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
                margin: '10px 16px 0 0',
                maxWidth: '378px',// To match the minimum size before mobile stylings are applied
            },
        },
        topMenu: {
            fontSize: '1rem',
            display: 'flex',
            minWidth: '400px',
            justifyContent: 'flex-end',
            '& a': {
                color: 'black',
                margin: 'auto 10%',
                fontFamily: "'Lato', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'",
            },
        },
        bookHelpButton: {
            'a&': {
                color: '#fff',
                fontSize: '1rem',
                margin: 'auto 0'
            }
        }
    })
);

const AppBar: React.FC<IAppBar> = ({ onDrawerToggle, headerRef, onHeaderImageLoad, headerIsSticky }) => {
    const classes = useStyles();
    const aiPlugin = useAppInsightsContext();

    const trackAppBarComponent = useComponentTracking(aiPlugin, 'AppBar');

    return (
        <div className={classes.root}>
            <MaterialUIAppBar
                id="header"
                ref={headerRef}
                position="fixed"
                className={classes.appBar + ' ' + (headerIsSticky ? classes.stickyAppBar : '')}
                onMouseOver={trackAppBarComponent}
            >
                <Toolbar disableGutters className={classes.toolBar}>
                    <Link href="/">
                        <img src={Logo} alt="Trusted.Help Logo" className={classes.logo} onLoad={onHeaderImageLoad} />
                    </Link>
                    <Hidden mdUp implementation="css">
                        <IconButton edge="start" onClick={onDrawerToggle} className={classes.menuButton}>
                            <MenuIcon fontSize="large" />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <div className={classes.topMenu}>
                                <Link href="/">LEARN</Link>
                                <Button href="/support" colour="recipes" className={classes.bookHelpButton}>
                                    BOOK A HELP SESSION
                                </Button>
                        </div>
                    </Hidden>
                </Toolbar>
            </MaterialUIAppBar>
        </div>
    );
};

export { drawerWidth, drawerMinWidth, appBarHeight };
export default AppBar;
