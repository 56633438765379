import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking
} from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";
import { APP_INSIGHTS } from "./../environmentVariables";

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS,
    //@ts-ignore
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory }
    },
    isCookieUseDisabled: true
  }
});
ai.loadAppInsights();
ai.trackPageView();

//@ts-ignore
ReactPlugin.prototype.trackEvent = function(
  event: string,
  customProperties: any
) {
  //@ts-ignore
  this._analyticsPlugin.trackEvent(event, customProperties);
};

export default (Component: React.FC) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
export { reactPlugin };
