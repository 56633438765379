declare global {
  interface Window {
    __trustedhelp_environment: {
      API_URI: string;
      APP_INSIGHTS: string;
    };
  }
}

const API_URI = window.__trustedhelp_environment.API_URI;
const APP_INSIGHTS = window.__trustedhelp_environment.APP_INSIGHTS;

export { API_URI, APP_INSIGHTS };
