import React, { useState } from 'react';
import { INavigationNode } from '../models/INavigationNode';
import { useData } from '../services/api';
import { useAppInsightsContext } from '../telemetry/AppInsightsContext';
import useCustomEvent from '../telemetry/useCustomEvent';
import { reactPlugin } from '../telemetry/AppInsights';
import ReactGA from 'react-ga';

interface IDocumentContext {
    documentBetweenRefreshes: INavigationNode | null;
    setDocumentBetweenRefreshes: React.Dispatch<React.SetStateAction<INavigationNode | null>>;
    onApplicationSelected: (navigationNode: INavigationNode | null) => void;
    onDocumentTypeSelected: (documentType: string) => void;
    onViewAllDocumentTypesSelected: (documentType: string) => void;
    onNavigationLeafSelected: (navigationLeaf: INavigationNode | null) => void;
    navigationTree: INavigationNode | null;
    isNavigationTreeLoading: boolean;
    refreshNavigationTree(): Promise<void>;
    selectedApplicationNode: INavigationNode | null;
    selectedDocumentType: string | null;
    selectedNavigationLeaf: INavigationNode | null;
    showAllDocumentsOfType: boolean;
}

const defaultDocumentContext: IDocumentContext = {
    documentBetweenRefreshes: null,
    setDocumentBetweenRefreshes: () => {},
    onApplicationSelected: (navigationNode: INavigationNode | null) => {},
    onDocumentTypeSelected: (documentType: string) => {},
    onViewAllDocumentTypesSelected: (documentType: string) => {},
    onNavigationLeafSelected: (navigationLeaf: INavigationNode | null) => {},
    navigationTree: null,
    isNavigationTreeLoading: false,
    refreshNavigationTree: () => Promise.resolve(),
    selectedApplicationNode: null,
    selectedDocumentType: null,
    selectedNavigationLeaf: null,
    showAllDocumentsOfType: false,
};

const DocumentContext = React.createContext<IDocumentContext>(defaultDocumentContext);

const DocumentProvider: React.FC = ({ children }) => {
    const aiPlugin = useAppInsightsContext();
    const [selectedApplicationNode, setSelectedApplicationNode] = useState<INavigationNode | null>(null);
    const [selectedDocumentType, setSelectedDocumentType] = useState<string | null>(null);
    const [selectedNavigationLeaf, setSelectedNavigationLeaf] = useState<INavigationNode | null>(null);
    const [showAllDocumentsOfType, setShowAllDocumentsOfType] = useState<boolean>(false);

    const [documentBetweenRefreshes, setDocumentBetweenRefreshes] = useState<INavigationNode | null>(null);
    // const [rootIndex, isRootIndexLoading, refreshRootIndex] = useData<IDocumentTreeNode>('trustedhelp/index/tree/root');

    const [navigationTree, isNavigationTreeLoading, refreshNavigationTree] =
        useData<INavigationNode>('trustedhelp/navigation/tree');

    const trackChangedDocument = useCustomEvent(aiPlugin, 'Changed document', {});

    const handleApplicationSelected = (navigationNode: INavigationNode | null) => {
        setSelectedApplicationNode(navigationNode);
        setSelectedNavigationLeaf(null);
        setShowAllDocumentsOfType(false);
    };

    const handleAllDocumentsTypeSelected = (documentType: string) => {
        setSelectedDocumentType(documentType);
        setSelectedApplicationNode(navigationTree);
        setSelectedNavigationLeaf(null);
        setShowAllDocumentsOfType(true);
    };

    const handleDocumentTypeSelected = (documentType: string) => {
        setSelectedDocumentType(documentType);
    };

    const handleNavigationLeafSelected = (navigationLeaf: INavigationNode | null) => {
        setSelectedNavigationLeaf(navigationLeaf);
        setShowAllDocumentsOfType(false);
        if (navigationLeaf !== null) {
            trackChangedDocument({ pathWithinTree: navigationLeaf.documentUrl });
            ReactGA.pageview(window.location.href);
        }
    };

    return (
        <DocumentContext.Provider
            value={{
                documentBetweenRefreshes,
                setDocumentBetweenRefreshes,
                onApplicationSelected: handleApplicationSelected,
                onDocumentTypeSelected: handleDocumentTypeSelected,
                onNavigationLeafSelected: handleNavigationLeafSelected,
                onViewAllDocumentTypesSelected: handleAllDocumentsTypeSelected,
                navigationTree,
                isNavigationTreeLoading,
                refreshNavigationTree,
                selectedApplicationNode,
                selectedDocumentType,
                selectedNavigationLeaf,
                showAllDocumentsOfType,
            }}
        >
            {children}
        </DocumentContext.Provider>
    );
};

export { DocumentProvider };
export default DocumentContext;
