import React, { useContext, useEffect, useRef, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import TermsAndConditions from './TermsAndConditions';
import DocumentContext from '../../services/DocumentContext';
import { useAppInsightsContext } from '../../telemetry/AppInsightsContext';
import useComponentTracking from '../../telemetry/useComponentTracking';
import ResponsiveNavigationDrawer from './ResponsiveNavigationDrawer';
import { INavigationNode } from '../../models/INavigationNode';
import Footer from './Footer';

interface ILayout {
    isDrawerPermitted: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            width: '80%',
            minHeight: '100vh',
            [theme.breakpoints.between('md', 'lg')]: {
                width: '75%',
            },
        },
    })
);

const Layout: React.FC<ILayout> = ({ children, isDrawerPermitted }) => {
    const classes = useStyles();
    const aiPlugin = useAppInsightsContext();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [headerIsSticky, setHeaderIsSticky] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const headerRef = useRef<any>();

    const { onApplicationSelected, navigationTree, isNavigationTreeLoading } = useContext(DocumentContext);

    const trackDocumentViewComponent = useComponentTracking(aiPlugin, 'DocumentView');

    window.onscroll = function() {makeHeaderSticky()};

    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollPosition(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollPosition]);

      const makeHeaderSticky = () => {
        if (scrollPosition > headerHeight) {
            setHeaderIsSticky(true);
        }
        else {
            setHeaderIsSticky(false);
        }
    }

    const handleDrawerToggle = () => setIsDrawerOpen(!isDrawerOpen);

    const onHeaderImageLoad = () => setHeaderHeight(headerRef.current.offsetHeight - 10);

    const handleApplicationSelected = (applicationNode: INavigationNode) => {
        onApplicationSelected(applicationNode);
    };

    return (
        <div className={classes.root}>
            <AppBar onDrawerToggle={handleDrawerToggle} headerRef={headerRef} onHeaderImageLoad={onHeaderImageLoad} headerIsSticky={headerIsSticky} />
            {isDrawerPermitted && (
                <ResponsiveNavigationDrawer
                    isDrawerOpen={isDrawerOpen}
                    onDrawerToggle={handleDrawerToggle}
                    navigationTree={navigationTree}
                    isLoading={isNavigationTreeLoading}
                    onApplicationSelected={handleApplicationSelected}
                />
            )}
            <main className={classes.content} onMouseOver={trackDocumentViewComponent}>
                <div style={{ minHeight: headerHeight }} />
                {children}
            </main>
            <Footer />
            <TermsAndConditions />
        </div>
    );
};

export default Layout;
