import { useState, useEffect, useRef } from "react";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export default function useCustomEvent(
  reactPlugin: ReactPlugin,
  eventName: string,
  eventData: any,
  skipFirstRun = true
) {
  const [data, setData] = useState(eventData);
  const firstRun = useRef(skipFirstRun);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    //@ts-ignore
    reactPlugin.trackEvent({ name: eventName }, data);
  }, [reactPlugin, data, eventName]);

  return setData;
}
