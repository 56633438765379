import React from 'react';
import IBaseSvgProps from '../IBaseSvgProps';

const TrustedHelp: React.FC<IBaseSvgProps> = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 26.408 26.033">
            <defs>
                <clipPath id="prefix__a" clipPathUnits="userSpaceOnUse">
                    <path d="M-17 1371h281V848H-17z" />
                </clipPath>
                <clipPath id="prefix__b" clipPathUnits="userSpaceOnUse">
                    <path d="M-3.98 1353.98h245v-42h-245z" />
                </clipPath>
                <clipPath id="prefix__c" clipPathUnits="userSpaceOnUse">
                    <path d="M-4.986 981.986h246v-42h-246z" />
                </clipPath>
                <clipPath id="prefix__q" clipPathUnits="userSpaceOnUse">
                    <path d="M0 1543h1280V0H0z" />
                </clipPath>
                <clipPath id="prefix__r" clipPathUnits="userSpaceOnUse">
                    <path d="M81.647 1493.514l-.12-37.992a2.443 2.443 0 012.437-2.451c1.746-.01 4.086-.014 5.832-.019a2.444 2.444 0 012.451 2.436l.12 37.992a2.442 2.442 0 01-2.435 2.451l-5.833.018h-.009a2.444 2.444 0 01-2.443-2.435" />
                </clipPath>
                <clipPath id="prefix__u" clipPathUnits="userSpaceOnUse">
                    <path d="M81.647 1493.514l-.12-37.992a2.443 2.443 0 012.437-2.451c1.746-.01 4.086-.014 5.832-.019a2.444 2.444 0 012.451 2.436l.12 37.992a2.442 2.442 0 01-2.435 2.451l-5.833.018h-.009a2.444 2.444 0 01-2.443-2.435" />
                </clipPath>
                <clipPath id="prefix__v" clipPathUnits="userSpaceOnUse">
                    <path d="M81.585 1495.96h24.262v-22.34H81.585z" />
                </clipPath>
                <clipPath id="prefix__w" clipPathUnits="userSpaceOnUse">
                    <path d="M81.492 1444.591a5.36 5.36 0 1110.72-.033 5.358 5.358 0 01-5.342 5.376h-.018a5.36 5.36 0 01-5.36-5.343" />
                </clipPath>
                <clipPath id="prefix__h" clipPathUnits="userSpaceOnUse">
                    <path d="M456 794h352V564H456z" />
                </clipPath>
                <clipPath id="prefix__j" clipPathUnits="userSpaceOnUse">
                    <path d="M607 1236h262v-82H607z" />
                </clipPath>
                <clipPath id="prefix__k" clipPathUnits="userSpaceOnUse">
                    <path d="M270 1241h350v-229H270z" />
                </clipPath>
                <clipPath id="prefix__m" clipPathUnits="userSpaceOnUse">
                    <path d="M1018 1125h229V775h-229z" />
                </clipPath>
                <clipPath id="prefix__p" clipPathUnits="userSpaceOnUse">
                    <path d="M368 1366h711v-78H368z" />
                </clipPath>
                <linearGradient
                    id="prefix__d"
                    spreadMethod="pad"
                    gradientTransform="matrix(-.18648 -58.95918 58.95932 -.18648 87.015 1495.94)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__t"
                    spreadMethod="pad"
                    gradientTransform="matrix(-.18648 -58.95918 58.95932 -.18648 87.015 1495.94)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopColor="#f9d423" />
                    <stop offset={1} stopColor="#ff4e50" />
                </linearGradient>
                <linearGradient
                    id="prefix__e"
                    spreadMethod="pad"
                    gradientTransform="scale(-58.95961) rotate(89.819 12.007 -13.445)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__y"
                    spreadMethod="pad"
                    gradientTransform="scale(-58.95961) rotate(89.819 12.007 -13.445)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopColor="#f9d423" />
                    <stop offset={1} stopColor="#ff4e50" />
                </linearGradient>
                <linearGradient
                    id="prefix__f"
                    spreadMethod="pad"
                    gradientTransform="matrix(88.44176 0 0 88.44176 98.258 1471.135)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__g"
                    spreadMethod="pad"
                    gradientTransform="matrix(88.44176 0 0 88.44176 98.258 1471.04)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__i"
                    spreadMethod="pad"
                    gradientTransform="matrix(308 0 0 -308 473.015 768.806)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__l"
                    spreadMethod="pad"
                    gradientTransform="matrix(308 0 0 -308 286 1216.164)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__n"
                    spreadMethod="pad"
                    gradientTransform="matrix(139 0 0 -139 1057 1068)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="prefix__o"
                    spreadMethod="pad"
                    gradientTransform="matrix(141 0 0 -141 1056 1068)"
                    gradientUnits="userSpaceOnUse"
                    y2={0}
                    x2={1}
                    y1={0}
                    x1={0}
                >
                    <stop offset={0} stopOpacity={0.9} stopColor="#fff" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <mask id="prefix__s" height={1} width={1} y={0} x={0} maskUnits="userSpaceOnUse">
                    <path d="M-32768 32767h65535v-65535h-65535z" fill="url(#prefix__d)" />
                </mask>
                <mask id="prefix__x" height={1} width={1} y={0} x={0} maskUnits="userSpaceOnUse">
                    <path d="M-32768 32767h65535v-65535h-65535z" fill="url(#prefix__e)" />
                </mask>
            </defs>
            <g clipPath="url(#prefix__q)" transform="matrix(.35278 0 0 -.35278 -18.125 532.756)">
                <path
                    d="M105.775 1495.88s4.386-3.677 6.05-5.074a.66.66 0 00-.023-1.03c-1.69-1.285-6.061-4.615-6.061-4.615l-13.4.043s-.07-21.906-.093-29.56a2.604 2.604 0 00-2.61-2.592l-5.517.018a2.598 2.598 0 00-2.593 2.609l.093 29.558-13.478.043a2.602 2.602 0 00-2.593 2.609l.017 5.518a2.598 2.598 0 002.609 2.593z"
                    fill="#8f26d9"
                    fillRule="evenodd"
                />
            </g>
            <g clipPath="url(#prefix__r)" transform="matrix(.35278 0 0 -.35278 -18.125 532.756)">
                <g mask="url(#prefix__s)">
                    <path
                        d="M81.647 1493.514l-.12-37.992a2.443 2.443 0 012.437-2.451c1.746-.01 4.086-.014 5.832-.019a2.444 2.444 0 012.451 2.436l.12 37.992a2.442 2.442 0 01-2.435 2.451l-5.833.018h-.009a2.444 2.444 0 01-2.443-2.435"
                        fill="url(#prefix__t)"
                    />
                </g>
            </g>
            <g clipPath="url(#prefix__u)" transform="matrix(.35278 0 0 -.35278 -18.125 532.756)">
                <g clipPath="url(#prefix__v)" opacity={0.9}>
                    <path d="M81.585 1473.624l.07 22.333 24.192-.076z" fill="#bf379e" fillRule="evenodd" />
                </g>
            </g>
            <g clipPath="url(#prefix__w)" transform="matrix(.35278 0 0 -.35278 -18.125 532.756)">
                <g mask="url(#prefix__x)">
                    <path
                        d="M81.492 1444.591a5.36 5.36 0 1110.72-.033 5.358 5.358 0 01-5.342 5.376h-.018a5.36 5.36 0 01-5.36-5.343"
                        fill="url(#prefix__y)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default TrustedHelp;
