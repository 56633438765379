import React, { Suspense, useState } from 'react';
import { Router, globalHistory } from '@reach/router';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Document, Home, Support, NotFound } from './pages';
import { ThemeContext } from './services/ThemeContext';
import { useThemeContext } from './services/useThemeContext';
import { DocumentProvider } from './services/DocumentContext';
import { AppInsightsContextProvider } from './telemetry/AppInsightsContext';
import Layout from './features/layout/Layout';

// TODO Add fancy loading page.
const LoadingPage = () => <div>Loading...</div>;

const App = () => {
    const themeContextHook = useThemeContext();
    const [isDrawerPermitted, setIsDrawerPermitted] = useState<boolean>(true);

    globalHistory.listen(({ location }) => {
        // @ts-ignore
        ga('set', 'page', location.pathname + location.search);
        // @ts-ignore
        ga('send', 'pageview');
    });

    return (
        <ThemeContext.Provider value={themeContextHook}>
            <ThemeProvider theme={themeContextHook.theme}>
                <SnackbarProvider maxSnack={4}>
                    <DocumentProvider>
                        <CssBaseline />
                        <AppInsightsContextProvider>
                            <Layout isDrawerPermitted={isDrawerPermitted}>
                                <Suspense fallback={<LoadingPage />}>
                                    <Router>
                                        <Home path="/" />
                                        <Document path="/document/*" />
                                        <Support path="/support" setIsDrawerPermitted={setIsDrawerPermitted} />
                                        <NotFound default />
                                    </Router>
                                </Suspense>
                            </Layout>
                        </AppInsightsContextProvider>
                    </DocumentProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default App;
